import { useEffect, useState } from "react";
import { Registration } from "./Registration";
import { BottomButtons } from "./BottomButtons";
import { CoffeeShops } from "./CoffeeShops";
import { Vouchers } from "./Vouchers";
import { ProfileInfo } from "./ProfileInfo";
import { QR } from "./QR"; // Импортируем компонент QR-кода
import { jwtDecode } from "jwt-decode";
import { useGetJWT } from "./GetJWT";
import {Settings} from "./Settings"

export const MainScreen = () => {
  const [clientPhotoURL, setClientPhotoURL] = useState(null);
  const [balance, setBalance] = useState(null);
  const [isRegistered, setIsRegistered] = useState(false);
  const [activeScreen, setActiveScreen] = useState("profileInfo");
  const [isQrVisible, setIsQrVisible] = useState(false); // Состояние видимости QR-кода
  const [isQrAnimating, setIsQrAnimating] = useState(false); // Состояние анимации
  const tg = window.Telegram.WebApp;

  const checkIsClient = () => {
    window.Telegram.WebApp.CloudStorage.getItem(
      "goose_client",
      (err, value) => {
        if (err) {
          console.error("Ошибка получения статуса клиента:", err);
          setIsRegistered(false);
        } else {
          setIsRegistered(value === "true");
        }
      }
    );
  };

  const getJWTToken = () => {
    window.Telegram.WebApp.CloudStorage.getItem(
      "goose_jwt_client",
      (err, token) => {
        if (err) {
          console.error("Ошибка получения JWT токена:", err);
          return false;
        }

        if (token) {
          const decoded = jwtDecode(token);
          setBalance(decoded.balance);
          return true;
        }
        return false;
      }
    );
  };

  useGetJWT(isRegistered);
  
  useEffect(() => {
    tg.ready();
    checkIsClient();

    if (isRegistered){
     getJWTToken();
    }

    if (isRegistered) {
      const photoURL = `https://ui-avatars.com/api/?name=${tg.initDataUnsafe.user?.first_name}&background=random&size=128&length=1`;
      setClientPhotoURL(photoURL);
    }
  }, [isRegistered]);

  // Обработчик для открытия QR-кода
  const openQrCode = () => {
    setIsQrAnimating(false); // Сбрасываем анимацию
    setIsQrVisible(true); // Показываем QR-код
  };

  // Обработчик для закрытия QR-кода при клике вне его области
  const handleQrClose = (e) => {
    if (e.target.classList.contains("qr-overlay")) {
      setIsQrAnimating(true); // Начинаем анимацию скрытия
      setTimeout(() => {
        setIsQrVisible(false); // Скрываем QR-код после анимации
        setIsQrAnimating(false); // Сбрасываем анимацию
      }, 200); // Убедитесь, что время соответствует CSS анимации
    }
  };

  if (!isRegistered) {
    return <Registration />;
  }

  const renderActiveScreen = () => {
    switch (activeScreen) {
      case "coffeeShops":
        return <CoffeeShops />;
      case "vouchers":
        return <Vouchers />;
      case "settings":
        return <Settings />;
      case "profileInfo":
      default:
        return (
          <ProfileInfo
            clientPhotoURL={clientPhotoURL}
            balance={balance}
            setActiveScreen={setActiveScreen}
            setIsQrVisible={openQrCode} // Передаем функцию для открытия QR
          />
        );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "flex-start",
        height: "100vh", // Установите высоту на 100vh, чтобы использовать всю высоту экрана
        overflowY: "auto", // Добавьте прокрутку по оси Y
      }}
      className="hide-scrollbar"
    >
      <div>{renderActiveScreen()}</div>
      <BottomButtons setActiveScreen={setActiveScreen} />

      {/* Переключение видимости QR-кода с анимацией */}
      {isQrVisible && (
        <div className={`qr-overlay ${isQrAnimating ? "fadeOut" : "fadeIn"}`} onClick={handleQrClose}>
          <div className="qr-container">
            <QR />
          </div>
        </div>
      )}
    </div>
  );
};
