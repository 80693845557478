import React from "react";
import { Cell, Badge, Avatar, List } from "@telegram-apps/telegram-ui";

export const CoffeeShops = () => {
  const coffeeShops = [
    {
      id: 1,
      title: "Щебетун",
      distance: "2,1 км",
      address: "ул. Фокина, 22, Брянск",
      status: "Открыто",
      imageUrl:
        "https://avatars.mds.yandex.net/get-altay/4961567/2a00000181ed2eb209a0d34307a673c6a64b/S_height",
      link: "https://yandex.ru/maps/-/CDhdiI5q"
    },
    {
      id: 2,
      title: "Surf Coffee X Woody",
      distance: "3,5 км",
      address: "просп. Ленина, 39, Брянск",
      status: "Закрыто",
      imageUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmL3vTtlqfwH7wLfCS2GhtXAUc8JLiujcUMg&s",
      link: "https://yandex.ru/maps/-/CDhdiUiD"
    },
  ];

  return (
    <div>
      <List>
        {coffeeShops.map((shop) => (
          <Cell
            onClick={() => window.Telegram.WebApp.openLink(shop.link)}
            style={{ padding: 5 }}
            key={shop.id}
            after={<Badge type="number">{shop.distance}</Badge>}
            before={<Avatar size={48} src={shop.imageUrl} />}
            description={
              <span
                style={{
                  color: shop.status === "Открыто" ? "#40c057" : "#f03e3e",
                }}
              >
                {shop.status}
              </span>
            }
            subtitle={shop.address}
            titleBadge={<Badge type="dot" />}
            interactiveAnimation="opacity"
          >
            {shop.title}
          </Cell>
        ))}
      </List>
    </div>
  );
};
