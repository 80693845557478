import { useEffect, useState } from "react";
import { QRCodeSVG } from "qrcode.react";

export const QR = () => {
  const [token, setToken] = useState("");

  useEffect(() => {
    window.Telegram.WebApp.CloudStorage.getItem(
      "goose_jwt_client",
      (err, jwtToken) => {
        if (err) {
          console.error("Ошибка получения JWT токена для QR-кода:", err);
        } else if (jwtToken) {
          setToken(jwtToken);
        }
      },
    );
  }, []);

  return <QRCodeSVG value={token || "Нет токена"} size={180} />;
};
