import React from "react";
import { Avatar, List } from "@telegram-apps/telegram-ui";
import { Balance } from "./Balance";
import { TopButtons } from "./TopButtons";
import { NewsAndPromo } from "./NewsAndPromo";
import { Advertisement } from "./Advertisement";


export const ProfileInfo = ({ clientPhotoURL, balance, setActiveScreen, setIsQrVisible }) => (
  <div>
    <TopButtons setActiveScreen={setActiveScreen} setIsQrVisible={setIsQrVisible} />
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Avatar size={74} src={clientPhotoURL} />
      <div style={{ textAlign: "center" }}>
        <Balance balance={balance} />
      </div>
      <NewsAndPromo />
      <Advertisement />
    </div>
  </div>
);
