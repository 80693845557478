import { Placeholder, LargeTitle, Headline } from "@telegram-apps/telegram-ui";

export const Balance = ({ balance }) => (
  <Placeholder style={{ padding: 0 }}>
    <div>
      <Headline
        plain
        weight="3"
        style={{ fontFamily: "Gluten", paddingTop: "10px" }}
      >
        Ваш баланс
      </Headline>
      <LargeTitle plain weight="3" style={{ paddingTop: "20px" }}>
        {balance} 🪶
      </LargeTitle>
    </div>
  </Placeholder>
);
