import { useEffect, useRef } from "react";

export const useGetJWT = (isRegistered) => {
  const isTokenFetched = useRef(false);

  const updateToken = async () => {
    try {
      const { id: userId, hash } = window.Telegram.WebApp.initDataUnsafe?.user || {};

      const tokenUrl = `https://loyalityn8n.vidikon.info/webhook/token/get/client?tguserid=${userId}&tghash=${hash}`;
      const response = await fetch(tokenUrl);
      const data = await response.json();

      if (data?.token) {
        window.Telegram.WebApp.CloudStorage.setItem(
          "goose_jwt_client",
          data.token,
          (err, success) => {
            if (err) console.error("Ошибка сохранения токена в CloudStorage:", err);
          }
        );
      } else {
        throw new Error("Не удалось получить токен");
      }
    } catch (error) {
      console.error("Ошибка обновления токена: ", error);
      window.Telegram.WebApp.CloudStorage.removeItem("goose_jwt_client");
      alert(
        "Мы не можем обновить ваш токен доступа к приложению. Операции с баллами невозможны :("
      );
    }
  };

  useEffect(() => {
    if (isRegistered && !isTokenFetched.current) {
      updateToken();
      isTokenFetched.current = true;
    }
  }, [isRegistered]);
};
