import React from "react";
import { IconButton } from "@telegram-apps/telegram-ui";
import { Icon24Settings, Icon24Qr } from "@vkontakte/icons";

export const TopButtons = ({ setActiveScreen, setIsQrVisible }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "10px",
      }}
    >
      {/* Кнопка открытия настроек */}
      <IconButton
        mode="plain"
        size="l"
        onClick={() => setActiveScreen("settings")}
      >
        <Icon24Settings />
      </IconButton>

      {/* Кнопка открытия QR-кода */}
      <IconButton
        mode="plain"
        size="l"
        onClick={() => setIsQrVisible(true)}
      >
        <Icon24Qr />
      </IconButton>
    </div>
  );
};
