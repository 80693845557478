import React from "react";
import { List, Section, Card, Cell, Chip } from "@telegram-apps/telegram-ui";

export const NewsAndPromo = () => {
  // Список карточек для демонстрации с добавлением ссылок
  const cardsData = [
    {
      id: 1,
      type: "Новое!",
      title: "Луи",
      subtitle: "У нас Гранд Капучино!",
      image: "https://cloud.vidikon.info/apps/files_sharing/publicpreview/jzFfi74sDPgAYtS?file=/&fileId=94550&x=3456&y=2304&a=true&etag=1ec0ce60db0e6b5865277a706ab4e410",
      link: "https://t.me/shebetoon/1780",
    },
    {
      id: 2,
      type: "Новость",
      title: "Шавелла",
      subtitle: "Работает в ночь", 
      image: "https://cloud.vidikon.info/apps/files_sharing/publicpreview/Z7GcZHjNkPH4T3R?file=/&fileId=94551&x=3456&y=2304&a=true&etag=9d75f09a6744502b48f778625b9e18a7",
      link: "https://t.me/shebetoon/1810",
    },
    {
      id: 3,
      type: "Новое!",
      title: "Шавелла",
      subtitle: "Мак-н-чиз в лаваше",
      image: "https://cloud.vidikon.info/apps/files_sharing/publicpreview/85jpWNSaNggJYPJ?file=/&fileId=94552&x=3456&y=2304&a=true&etag=23b36d85542e083901e0b23bec8217de",
      link: "https://t.me/shebetoon/1776",
    },
  ];

  return (
    <div className="newsAndPromo" style={{ width: "100%" }}>
      <List
        style={{
          background: "var(--tgui--secondary_bg_color)",
          padding: "10px 0 10px 0",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Section header="Новости и акции">
          <div style={{ position: "relative" }}>
            <div
              style={{
                display: "flex",
                overflowX: "auto",
                paddingLeft: "5px",
                gap: "10px",
                width: "99%",
              }}
              className="hide-scrollbar" // Класс для скрытия скролла
            >
              {cardsData.map((card) => (
                <Card
                  key={card.id}
                  type="ambient"
                  style={{
                    flex: "0 0 auto",
                    width: "200px", // Уменьшенная ширина карточки
                    height: "180px", // Уменьшенная высота карточки
                    position: "relative",
                  }}
                  onClick={() => window.open(card.link, "_blank")} // Переход по ссылке при клике
                >
                  <img
                    alt={card.title}
                    src={card.image}
                    style={{
                      height: "100%",
                      objectFit: "cover",
                      width: "100%",
                      
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: "linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0))",
                      zIndex: 1,
                    }}
                  />
                  <Chip
                    readOnly
                    style={{
                      position: "absolute",
                      top: "10px",
                      left: "10px",
                      zIndex: 2,
                    }}
                  >
                    {card.type}
                  </Chip>
                  <Cell
                    readOnly
                    subtitle={card.subtitle}
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      left: "0px",
                      zIndex: 2,
                      color: "var(--tg-theme-text-color)"
                    }}
                  >
                    {card.title}
                  </Cell>
                </Card>
              ))}
            </div>
          </div>
        </Section>
      </List>
    </div>
  );
};
