import React, { useState } from "react";
import { Tabbar } from "@telegram-apps/telegram-ui";
import {
  Icon24Settings,
  Icon24Qr,
  Icon24Place,
  Icon24TicketOutline,
  Icon24MailOutline,
  Icon24Home,
  Icon24User,
} from "@vkontakte/icons";

export const BottomButtons = ({ setActiveScreen }) => {
  const [currentTab, setCurrentTab] = useState("qr"); // по умолчанию выбран QR

  const tabs = [
    { id: "coffeeShops", text: "Кофейни", Icon: Icon24Place },
    { id: "qr", text: "Главная", Icon: Icon24User },
    { id: "vouchers", text: "Купоны", Icon: Icon24TicketOutline },
  ];
  return (
    <div style={{ height: 80 }}>
      {" "}
      {/* Поднимем высоту меню */}
      <Tabbar>
        {tabs.map(({ id, text, Icon }) => (
          <Tabbar.Item
            key={id}
            text={text}
            selected={id === currentTab}
            onClick={() => {
              setCurrentTab(id);
              setActiveScreen(id); // обновляем экран
            }}
            style={{ paddingBottom: 25 }}
          >
            <Icon />
          </Tabbar.Item>
        ))}
      </Tabbar>
    </div>
  );
};
