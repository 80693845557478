import "@telegram-apps/telegram-ui/dist/styles.css";
import { AppRoot } from "@telegram-apps/telegram-ui";
import { MainScreen } from "./components/MainScreen";

const app = window.Telegram.WebApp;
app.ready();
app.expand();
app.HapticFeedback.impactOccurred("light");

const App = () => (
  <AppRoot>
    <MainScreen />
  </AppRoot>
);

export default App;
