import { useState, useEffect, useRef } from "react";
import { MainScreen } from "./MainScreen";
import { CompanyName } from "./CompanyName";

import "./skeleton.css"; // Импортируем стили

export const Registration = () => {
  const [isClient, setIsClient] = useState(null); // null = идет проверка, true = привязан, false = не привязан
  const hasRegistered = useRef(false); // флаг для предотвращения повторных запросов

  const registerClient = async () => {
    if (hasRegistered.current) return; // Проверяем, был ли уже выполнен запрос
    hasRegistered.current = true; // Устанавливаем флаг, чтобы запрос больше не запускался

    try {
      const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id;
      const hash = window.Telegram.WebApp.initDataUnsafe?.hash;
      const username = window.Telegram.WebApp.initDataUnsafe?.user?.username;
      const first_name =
        window.Telegram.WebApp.initDataUnsafe?.user?.first_name;
      const last_name = window.Telegram.WebApp.initDataUnsafe?.user?.last_name;

      const tokenUrl = `https://loyalityn8n.vidikon.info/webhook/registarion/client`;

      const response = await fetch(tokenUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstname: first_name || "null",
          lastname: last_name || "null",
          tguserid: userId,
          tghash: hash,
          tgusername: username,
        }),
      });

      const data = await response.json();

      if (!data.err) {
        setIsClient(true);
        window.Telegram.WebApp.CloudStorage.setItem(
          "goose_client",
          "true",
          (err) => {
            if (err) console.error("Ошибка сохранения в CloudStorage:", err);
          },
        );
      } else {
        setIsClient(false);
      }
    } catch (error) {
      console.error("Ошибка регистрации:", error);
      setIsClient(false);
      window.location.reload();
    }
  };

  useEffect(() => {
    registerClient();
  }, []);

  if (isClient === null) {
    return (
      <img
        alt="Telegram sticker"
        src="logo_grey.png"
        className="skeleton"
        style={{
          width: "100px",
          height: "100px",
          display: "block",
          marginTop: "70%",
          marginLeft: "auto",
          marginRight: "auto",
          borderRadius: "20%",
        }}
      />
    );
  }

  if (isClient) {
    return <MainScreen />;
  }

  return <CompanyName text="Мы не можем вас зарегистрировать" />;
};
