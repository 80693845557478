import React from "react";

export const Settings = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "50%",
    }}
  >
    <div> Настройки </div>
  </div>
);
