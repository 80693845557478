import React from "react";
import { Image, Section, Cell } from "@telegram-apps/telegram-ui";

export const Advertisement = () => {
    return (
        <Section header="Реклама"
        onClick={() => window.Telegram.WebApp.openLink("https://yandex.ru/maps/discovery/podborka_gde-est-gde-poprobovat-shaurmu_bryansk-191/?indoorLevel=1&ll=34.355205%2C53.274793&z=12.8")}
        
        >
            
        <div style={{ position: "relative" }}>
          <div
            style={{
                display: "flex",
                width: "99%",
                marginBottom: "120px"
            }}
            className="hide-scrollbar" // Класс для скрытия скролла
          >
        <Image
        size={196}
        style={{display: "block", marginLeft:"auto", "marginRight": "auto", width: "380px"}}
        src="https://avatars.mds.yandex.net/get-altay/6272620/2a000001862b0c9f3a95e99523da4445432c/XXXL"
        />
         <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: "linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0))",
                      zIndex: 0,
                      borderRadius: "10px"
                    }}
                  />
        <Cell
                    readOnly
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      left: "0px",
                      zIndex: 0,
                      color: "var(--tg-theme-text-color)"
                    }}
                  >
                    6 мест с вкусной шаурмой в Брянске
                  </Cell>
  </div>
  </div>
  </Section>  
  )
}