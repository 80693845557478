import { Placeholder, LargeTitle } from "@telegram-apps/telegram-ui";

export const CompanyName = () => (
  <Placeholder style={{ padding: 0 }}>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        position: "fixed",
        top: 20,
      }}
    >
      <img
        alt="Telegram sticker"
        src="logo.jpeg"
        style={{
          width: "50px",
          height: "50px",
          marginRight: "10px",
          "border-radius": "20%",
        }} // Уменьшил размер изображения
      />
      <LargeTitle
        plain
        weight="3"
        style={{ fontFamily: "Gluten", paddingTop: "10px" }}
      >
        Goose
      </LargeTitle>{" "}
    </div>
  </Placeholder>
);
